/** @format */

import React, { useEffect, useRef, useState } from "react";
import interceptor from "../../interceptors/axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Button, IconButton, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { MDBTextArea, MDBValidationItem } from "mdb-react-ui-kit";

// Selection component from react-select
import Select from "react-select";

// information library from react-toastify
import { toast } from "react-toastify";

// style
import "./style.css";

// import cities data
import {
  Andijon,
  Buxoro,
  Fargona,
  jins,
  Jizzax,
  Namangan,
  Navoiy,
  Qaraqalpaq,
  Qashqadaryo,
  Samarqand,
  middle_degree,
  middle,
  prime,
  viloyat,
  daraja,
  Sirdaryo,
  Surxandaryo,
  TashkentCity,
  TashkentRegion,
  Xorazm,
  country,
  millat,
  categories,
} from "../../components/Data/regions.js";

//navigation
import { useNavigate } from "react-router";

//icons
import FirstPageIcon from "@mui/icons-material/FirstPage";

// redux
import { useSelector } from "react-redux";

//loader
import Spinner from "../../components/Spinner/index.jsx";

import axios from "axios";
import { getDate } from "../../interceptors/globalfunction";

// URLS-->
const PROFILE_PICTURE_URL =
  // "https://qabul.nordicmail.uz/api/v1/file/upload/profile-photo";
  "https://admission.nordicun.uz/api/v1/file/upload/profile-photo";
const DIPLOM_PICTURE_URL =
  "https://admission.nordicun.uz/api/v1/file/upload/diploma";
const PASSPORT_PICTURE_URL =
  "https://admission.nordicun.uz/api/v1/file/upload/passport";
const TAVSIYANOMA_PICTURE_URL =
  "https://admission.nordicun.uz/api/v1/file/upload/tavsiyanoma";
const IELTS_COPY_URL = "https://admission.nordicun.uz/api/v1/file/upload/ielts";
const MEHNAT_DAFTARCHA_URL =
  "https://admission.nordicun.uz/api/v1/file/upload/mehnat/daftar";

export default function Update() {
  let navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.FullReducer);

  const [fieldData, setFieldData] = useState([]);
  const [name, setName] = useState(userInfo?.userInfo?.firstName || "");
  const [middleName, setMiddleName] = useState(
    userInfo?.userInfo?.fatherName || ""
  );
  const [surname, setSurname] = useState(userInfo?.userInfo?.lastName || "");
  const [passportIsGiven, setPassportIsGiven] = useState(
    userInfo?.passportInfo?.docGivenBy || ""
  );
  const [companyName, setCompanyName] = useState(
    userInfo?.diplomaInfo?.muassasaNomi || ""
  );
  const [phone, setPhone] = useState(userInfo?.userInfo?.mobilePhone);

  const [diplomSeria, setDiplomSeria] = useState(
    userInfo?.diplomaInfo?.diplomaSerial || ""
  );
  const [graduatedYear, setGraduatedYear] = useState(
    userInfo?.diplomaInfo?.graduatedYear || ""
  );
  const [streetAndHome, setStreetAndHome] = useState(
    userInfo?.passportInfo?.streetAndHome || ""
  );
  const [passportNumber, setPassportNumber] = useState(
    userInfo?.passportInfo?.docNumber || ""
  );
  const [diplomNumber, setDiplomNumber] = useState(
    userInfo?.diplomaInfo?.diplomaNumber || ""
  );
  const email = useRef();
  const comment = useRef();
  const [passportSeria, setPassportSeria] = useState(
    userInfo?.passportInfo?.docSerial || ""
  );

  const [type, setType] = useState(null);
  const [passportRegion, setPassportRegion] = useState(null);
  const [diplomRegion, setDiplomRegion] = useState(null);

  const [fieldValue, setFieldValue] = useState([]);
  const [value, setValue] = useState(dayjs(userInfo?.passportInfo?.birthDate));
  const [docGivenDate, setDocGivenDate] = useState(
    dayjs(userInfo?.passportInfo?.docGivenDate)
  );
  const [submitHandler, setSubmitHandler] = useState(
    userInfo?.fieldInfo?.fieldType
  );
  const [setTypeHandler] = useState(userInfo?.fieldInfo?.studyType);
  const [passportId, setPassportId] = useState(
    userInfo?.passportInfo?.passportAttachment?.id || null
  );
  const [tavsiyanomaId, setTavsiyanomaId] = useState(
    userInfo?.diplomaInfo?.tavsiyanomaAttachment?.id || null
  );
  const [diplomId, setDiplomId] = useState(
    userInfo?.diplomaInfo?.diplomaAttachment?.id || null
  );
  const [ieltsId, setIeltsId] = useState(
    userInfo?.diplomaInfo?.ieltsAttachment?.id || null
  );
  const [profileId, setProfileId] = useState(
    userInfo?.userInfo?.profilePhoto?.id || null
  );
  const [daftarId, setDaftarId] = useState(
    userInfo?.diplomaInfo?.mehnatDaftarAttachment?.id || null
  );
  const [fieldId, setFieldId] = useState(userInfo?.fieldInfo?.fieldId);

  const [sex, setSex] = useState(userInfo?.passportInfo?.gender);

  const [degree, setDegree] = useState(userInfo?.diplomaInfo?.malumoti);
  const [educationType, setEducationType] = useState(
    userInfo?.diplomaInfo?.muassasaTuri
  );
  const [citizenship, setCitizenship] = useState(
    userInfo?.passportInfo?.citizenship
  );
  const [nationality, setNationality] = useState(
    userInfo?.passportInfo?.nationality
  );
  const [passportAddress, setPassportAddress] = useState(
    userInfo?.passportInfo?.district
  );
  const [passportLivePlace, setPassportLivePlace] = useState(
    userInfo?.passportInfo?.region
  );
  const [diplomLivePlace, setDiplomLivePlace] = useState(
    userInfo?.diplomaInfo?.region
  );
  const [diplomAddress, setDiplomAddress] = useState(
    userInfo?.diplomaInfo?.district
  );

  const turi = [];

  useEffect(() => {
    (async () => {
      const { data } = await interceptor.get("/api/v1/field/get/list");
      setFieldData(data?.data);
    })();
  }, []);

  const isFoundType = (typeOfStudy) => {
    if (typeOfStudy === "MAXSUS_SIRTQI") {
      setFieldValue(Object.values(fieldData)[0] || "");
      setSubmitHandler("MAXSUS_SIRTQI");
      setTypeHandler("BAKALAVR");
    } else if (typeOfStudy === "KUNDUZGI") {
      setFieldValue(Object.values(fieldData)[3] || "");
      setSubmitHandler("KUNDUZGI");
      setTypeHandler("BAKALAVR");
    } else if (typeOfStudy === "MAGISTR") {
      setFieldValue(Object.values(fieldData)[1] || "");
      setSubmitHandler("KUNDUZGI");
      setTypeHandler("MAGISTR");
    } else if (typeOfStudy === "SIRTQI") {
      setFieldValue(Object.values(fieldData)[2] || "");
      setSubmitHandler("SIRTQI");
      setTypeHandler("BAKALAVR");
    }
  };

  for (let i = 0; i < fieldValue.length; i++) {
    turi.push({
      value: fieldValue[i].fieldName,
      label: fieldValue[i].fieldName,
    });
  }

  const profilePicture = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await interceptor
        .post(PROFILE_PICTURE_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Admin-Token": localStorage.getItem("access"),
          },
        })
        .then((reqst) => setProfileId(reqst?.data?.data?.id));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };
  const passportPicture = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await axios
        .post(PASSPORT_PICTURE_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Admin-Token": localStorage.getItem("access"),
          },
        })
        .then((reqst) => setPassportId(reqst?.data?.data?.id));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };
  const diplomPicture = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await axios
        .post(DIPLOM_PICTURE_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Admin-Token": localStorage.getItem("access"),
          },
        })
        .then((reqst) => setDiplomId(reqst?.data?.data?.id));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };
  const tavsiyanomaPicture = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      await axios
        .post(TAVSIYANOMA_PICTURE_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Admin-Token": localStorage.getItem("access"),
          },
        })
        .then((reqst) => setTavsiyanomaId(reqst?.data?.data?.id));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };
  const IeltsCopy = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await axios
        .post(IELTS_COPY_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Admin-Token": localStorage.getItem("access"),
          },
        })
        .then((reqst) => setIeltsId(reqst?.data?.data?.id));
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };
  const mehnatDaftar = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      await axios
        .post(MEHNAT_DAFTARCHA_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((reqst) => {
          setDaftarId(reqst?.data?.data?.id);
        });
    } catch (err) {
      if (err?.response.status === 500) {
        toast.error("File hajmi katta!");
      }
    }
  };
  const handleDegree = (selectedOptions) => {
    setDegree(selectedOptions.value);
  };
  const handleSex = (selectedOptions) => {
    setSex(selectedOptions.value);
  };
  const handleEducationType = (selectedOptions) => {
    setEducationType(selectedOptions.value);
  };
  const handleCitizenship = (selectedOptions) => {
    setCitizenship(selectedOptions.value);
  };
  const handleNationality = (selectedOptions) => {
    setNationality(selectedOptions.value);
  };
  const handlePassportAddress = (selectedOptions) => {
    setPassportAddress(selectedOptions.value);
  };
  const handlePassportLivePlace = (selectedOptions) => {
    setPassportLivePlace(selectedOptions.value);
  };
  const handleDiplomAddress = (selectedOptions) => {
    setDiplomAddress(selectedOptions.value);
  };
  const handleDiplomLivePlace = (selectedOption) => {
    setDiplomLivePlace(selectedOption.value);
  };

  const findFieldId = (id) => {
    for (let i = 0; i < fieldValue.length; i++) {
      if (id.value === fieldValue[i]?.fieldName) {
        setFieldId(fieldValue[i]?.id);
      }
    }
  };

  useEffect(() => {
    if (degree === "Oliy") {
      setType(prime);
    } else if (degree === "O'rta") {
      setType(middle);
    } else {
      setType(middle_degree);
    }
  }, [degree]);
  useEffect(() => {
    if (passportLivePlace === "Andijon viloyati") {
      setPassportRegion(Andijon);
    } else if (passportLivePlace === "Qashqadaryo viloyati") {
      setPassportRegion(Qashqadaryo);
    } else if (passportLivePlace === "Qoraqalpog‘iston Respublikasi") {
      setPassportRegion(Qaraqalpaq);
    } else if (passportLivePlace === "Buxoro viloyati") {
      setPassportRegion(Buxoro);
    } else if (passportLivePlace === "Toshkent viloyati") {
      setPassportRegion(TashkentRegion);
    } else if (passportLivePlace === "Sirdaryo viloyati") {
      setPassportRegion(Sirdaryo);
    } else if (passportLivePlace === "Surxandaryo viloyati") {
      setPassportRegion(Surxandaryo);
    } else if (passportLivePlace === "Namangan viloyati") {
      setPassportRegion(Namangan);
    } else if (passportLivePlace === "Farg'ona viloyati") {
      setPassportRegion(Fargona);
    } else if (passportLivePlace === "Xorazm viloyati") {
      setPassportRegion(Xorazm);
    } else if (passportLivePlace === "Navoiy viloyati") {
      setPassportRegion(Navoiy);
    } else if (passportLivePlace === "Samarqand viloyati") {
      setPassportRegion(Samarqand);
    } else if (passportLivePlace === "Jizzax viloyati") {
      setPassportRegion(Jizzax);
    } else {
      setPassportRegion(TashkentCity);
    }
  }, [passportLivePlace]);
  useEffect(() => {
    if (diplomLivePlace === "Andijon viloyati") {
      setDiplomRegion(Andijon);
    } else if (diplomLivePlace === "Qashqadaryo viloyati") {
      setDiplomRegion(Qashqadaryo);
    } else if (diplomLivePlace === "Qoraqalpog‘iston Respublikasi") {
      setDiplomRegion(Qaraqalpaq);
    } else if (diplomLivePlace === "Buxoro viloyati") {
      setDiplomRegion(Buxoro);
    } else if (diplomLivePlace === "Toshkent viloyati") {
      setDiplomRegion(TashkentRegion);
    } else if (diplomLivePlace === "Sirdaryo viloyati") {
      setDiplomRegion(Sirdaryo);
    } else if (diplomLivePlace === "Surxandaryo viloyati") {
      setDiplomRegion(Surxandaryo);
    } else if (diplomLivePlace === "Namangan viloyati") {
      setDiplomRegion(Namangan);
    } else if (diplomLivePlace === "Farg'ona viloyati") {
      setDiplomRegion(Fargona);
    } else if (diplomLivePlace === "Xorazm viloyati") {
      setDiplomRegion(Xorazm);
    } else if (diplomLivePlace === "Navoiy viloyati") {
      setDiplomRegion(Navoiy);
    } else if (diplomLivePlace === "Samarqand viloyati") {
      setDiplomRegion(Samarqand);
    } else if (diplomLivePlace === "Jizzax viloyati") {
      setDiplomRegion(Jizzax);
    } else {
      setDiplomRegion(TashkentCity);
    }
  }, [diplomLivePlace]);

  const ErrorFunction = (name) => {
    if (!name) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (
      name.trim().length > 0 &&
      surname.trim().length > 0 &&
      middleName.trim().length > 0 &&
      phone.trim().length > 0 &&
      degree.length > 0 &&
      educationType.length > 0 &&
      companyName.trim().length > 0 &&
      graduatedYear &&
      diplomLivePlace.length > 0 &&
      diplomAddress.length > 0 &&
      citizenship.length > 0 &&
      nationality.length > 0 &&
      passportLivePlace.length > 0 &&
      streetAndHome.trim().length > 0 &&
      passportSeria.trim().length > 0 &&
      passportNumber.length > 0 &&
      passportIsGiven.trim().length > 0 &&
      sex.length > 0 &&
      profileId &&
      diplomId &&
      passportId &&
      tavsiyanomaId &&
      daftarId &&
      fieldId
    ) {
      const Data = {
        userId: `${localStorage.getItem("pageId")}`,
        data: {
          firstName: name.trim(),
          lastName: surname.trim(),
          fatherName: middleName.trim(),
          email: email.current.value.trim(),
          mobilePhone: phone.trim(),
          profilePhotoAttachmentId: profileId,
          comment: comment.current.value.trim(),
        },
        diploma: {
          malumoti: degree,
          muassasaTuri: educationType,
          muassasaNomi: companyName.trim(),
          graduatedYear: graduatedYear,
          region: diplomLivePlace,
          district: diplomAddress,
          diplomaSerial: diplomSeria.trim(),
          diplomaNumber: diplomNumber,
          diplomaAttachmentId: diplomId,
          tavsiyanomaAttachmentId: tavsiyanomaId,
          ieltsAttachmentId: ieltsId,
          mehnatDaftarAttachmentId: daftarId,
        },
        passport: {
          citizenship: citizenship,
          nationality: nationality,
          region: passportLivePlace,
          district: passportAddress,
          streetAndHome: streetAndHome.trim(),
          birthDate: getDate(value),
          docSerial: passportSeria.trim(),
          docNumber: passportNumber.trim(),
          docGivenBy: passportIsGiven.trim(),
          docGivenDate: getDate(docGivenDate),
          gender: sex,
          passportAttachmentId: passportId,
        },
        field: {
          fieldId: fieldId,
        },
      };
      try {
        await interceptor
          .post("/api/v1/admin/user/update", JSON.stringify(Data), {
            headers: {
              "X-Admin-Token": localStorage.getItem("access"),
            },
          })
          .then((reqst) => {
            if (reqst.status === 200) {
              toast.success("Muvaffaqiyatli amalga oshirildi!");
              navigate(-1);
            }
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.info("Ma'lumotlar to'liq to'ldirilmagan!");
    }
  };

  const onSubmitHandlerMagistr = async (event) => {
    event.preventDefault();
    if (
      name.trim().length > 0 &&
      surname.trim().length > 0 &&
      middleName.trim().length > 0 &&
      phone.trim().length > 0 &&
      degree.length > 0 &&
      educationType.length > 0 &&
      companyName.trim().length > 0 &&
      graduatedYear &&
      diplomLivePlace.length > 0 &&
      diplomAddress.length > 0 &&
      diplomSeria.trim().length > 0 &&
      diplomNumber.trim().length > 0 &&
      citizenship.length > 0 &&
      nationality.length > 0 &&
      passportLivePlace.length > 0 &&
      streetAndHome.trim().length > 0 &&
      passportSeria.trim().length > 0 &&
      passportNumber.trim().length > 0 &&
      passportIsGiven.trim().length > 0 &&
      sex.length > 0 &&
      profileId &&
      diplomId &&
      passportId &&
      fieldId
    ) {
      const Data = {
        userId: `${localStorage.getItem("pageId")}`,
        data: {
          firstName: name.trim(),
          lastName: surname.trim(),
          fatherName: middleName.trim(),
          email: email.current.value.trim(),
          mobilePhone: phone.trim(),
          profilePhotoAttachmentId: profileId,
          comment: comment.current.value.trim(),
        },
        diploma: {
          malumoti: degree,
          muassasaTuri: educationType,
          muassasaNomi: companyName.trim(),
          graduatedYear: graduatedYear,
          region: diplomLivePlace,
          district: diplomAddress,
          diplomaSerial: diplomSeria.trim(),
          diplomaNumber: diplomNumber.trim(),
          diplomaAttachmentId: diplomId,
          ieltsAttachmentId: ieltsId,
          tavsiyanomaAttachmentId: tavsiyanomaId,
        },
        passport: {
          citizenship: citizenship,
          nationality: nationality,
          region: passportLivePlace,
          district: passportAddress,
          streetAndHome: streetAndHome.trim(),
          birthDate: getDate(value),
          docSerial: passportSeria.trim(),
          docNumber: passportNumber.trim(),
          docGivenBy: passportIsGiven.trim(),
          docGivenDate: getDate(docGivenDate),
          gender: sex,
          passportAttachmentId: passportId,
        },
        field: {
          fieldId: fieldId,
        },
      };

      try {
        await interceptor
          .post("/api/v1/admin/user/update", JSON.stringify(Data), {
            headers: {
              "X-Admin-Token": localStorage.getItem("access"),
            },
          })
          .then((reqst) => {
            if (reqst.status === 200) {
              toast.success("Muvaffaqiyatli amalga oshirildi!");
              navigate(-1);
            }
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.info("Ma'lumotlar to'liq to'ldirilmagan!");
    }
  };
  const isError = {
    control: (styles) => ({ ...styles, border: "1px solid #d32f2f" }),
  };
  const colorStyles = {
    control: (styles) => ({ ...styles }),
  };
  return (
    <div>
      {userInfo ? (
        <div>
          <Box sx={{ p: 3 }}>
            <div
              style={{
                display: "flex",
                marginBottom: "12px",
                alignItems: "center",
              }}
            >
              <IconButton sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                <FirstPageIcon />
              </IconButton>
              <Typography variant={"h5"}>
                Shaxsiy ma'lumotlarni tahrirlash
                <span style={{ fontSize: "18px", color: "#fdc632" }}>
                  {userInfo?.fieldInfo?.studyType === "BAKALAVR"
                    ? " (Bakalaviriat)"
                    : " (Magistratura)"}
                </span>
              </Typography>
            </div>
            <Grid container spacing={2} sx={{ py: 2 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  id='outlined-size-small'
                  size='small'
                  fullWidth
                  type='text'
                  required
                  label='Ism'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={ErrorFunction(name)}
                  helperText={
                    ErrorFunction(name) && "Iltimos, ma'lumotingizni kiriting"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id='outlined-size-small'
                  size='small'
                  fullWidth
                  type='text'
                  label='Familiya'
                  required
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  error={ErrorFunction(surname)}
                  helperText={
                    ErrorFunction(surname) &&
                    "Iltimos, ma'lumotingizni kiriting"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id='outlined-size-small'
                  size='small'
                  fullWidth
                  type='text'
                  required
                  label='Otasining ismi'
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                  error={ErrorFunction(middleName)}
                  helperText={
                    ErrorFunction(middleName) &&
                    "Iltimos, ma'lumotingizni kiriting"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture
                    label="Tug'ilgan sanasi"
                    openTo='year'
                    views={["year", "month", "day"]}
                    inputFormat='DD/MM/YYYY'
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size='small'
                        error={ErrorFunction(value)}
                        helperText={
                          ErrorFunction(value) &&
                          "Iltimos, tug'ilgan sanangizni kiriting"
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                {sex ? (
                  <span>Iltimos, jinsingizni belgilang *</span>
                ) : (
                  <span className='text-error'>
                    Iltimos, jinsingizni belgilang *
                  </span>
                )}
                <Select
                  styles={sex ? colorStyles : isError}
                  options={jins}
                  placeholder={
                    sex
                      ? userInfo?.passportInfo?.gender === "MALE"
                        ? "Erkak"
                        : "Ayol"
                      : "Select..."
                  }
                  required
                  onChange={handleSex}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {profileId ? (
                  <span>
                    Fotosurat 3.5 X 4.5, 600 X 800 piksel 5 mb dan oshmagan
                    holda ( png, jpg, jpeg ) *
                  </span>
                ) : (
                  <span className='text-error'>
                    Fotosurat 3.5 X 4.5, 600 X 800 piksel 5 mb dan oshmagan
                    holda ( png, jpg, jpeg ) *
                  </span>
                )}
                <MDBValidationItem feedback='Smth' invalid>
                  <input
                    type='file'
                    className={
                      profileId ? "form-control" : "form-control-error"
                    }
                    aria-label='file example'
                    required
                    accept='image/png,image/jpg,image/jpeg'
                    onChange={profilePicture}
                  />
                  {userInfo?.userInfo?.profilePhoto?.fullPath && (
                    <Button
                      href={userInfo?.userInfo?.profilePhoto?.fullPath}
                      target='_blank'
                      className={"text-subtitle"}
                    >
                      File: {userInfo?.userInfo?.profilePhoto?.originalName}
                    </Button>
                  )}
                </MDBValidationItem>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: 3 }}>
            <Typography variant={"h5"}>
              Passport ma'lumotlar tahrirlash
              <span style={{ fontSize: "18px", color: "#fdc632" }}>
                {userInfo?.fieldInfo?.studyType === "BAKALAVR"
                  ? " (Bakalaviriat)"
                  : " (Magistratura)"}
              </span>
            </Typography>
            <Grid container spacing={2} sx={{ py: 2 }}>
              <Grid item xs={12} md={6} sx={{ zIndex: "999" }}>
                {citizenship ? (
                  <span>Fuqoroligi *</span>
                ) : (
                  <span className='text-error'>Fuqoroligi *</span>
                )}
                <Select
                  options={country}
                  styles={citizenship ? colorStyles : isError}
                  placeholder={userInfo?.passportInfo?.citizenship}
                  classNamePrefix='my-custom-react-select'
                  onChange={handleCitizenship}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ zIndex: "998" }}>
                {nationality ? (
                  <span>Millati *</span>
                ) : (
                  <span className='text-error'>Millati *</span>
                )}
                <Select
                  styles={nationality ? colorStyles : isError}
                  options={millat}
                  placeholder={userInfo?.passportInfo?.nationality}
                  onChange={handleNationality}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ zIndex: "997" }}>
                {passportLivePlace ? (
                  <span>Yashash hududi *</span>
                ) : (
                  <span className='text-error'>Yashash hududi *</span>
                )}
                <Select
                  styles={passportLivePlace ? colorStyles : isError}
                  options={viloyat}
                  placeholder={userInfo?.passportInfo?.region}
                  onChange={handlePassportLivePlace}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ zIndex: "996" }}>
                {passportAddress ? (
                  <span>Tuman *</span>
                ) : (
                  <span className='text-error'>Tuman *</span>
                )}
                <Select
                  styles={passportAddress ? colorStyles : isError}
                  options={passportRegion}
                  placeholder={userInfo?.passportInfo?.district}
                  onChange={handlePassportAddress}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture
                    label='Passport berilgan sanasi'
                    openTo='year'
                    views={["year", "month", "day"]}
                    inputFormat='DD/MM/YYYY'
                    value={docGivenDate}
                    onChange={(newValue) => {
                      setDocGivenDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size={"small"}
                        error={ErrorFunction(docGivenDate)}
                        helperText={
                          ErrorFunction(docGivenDate) &&
                          "Iltimos, passport berilgan sanasini kiriting"
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id='outlined-size-small'
                  size='small'
                  fullWidth
                  type='text'
                  required
                  label="Ko'cha nomi, uy va xonadon raqami"
                  value={streetAndHome}
                  onChange={(e) => setStreetAndHome(e.target.value)}
                  error={ErrorFunction(streetAndHome)}
                  helperText={
                    ErrorFunction(streetAndHome)
                      ? "Iltimos, ma'lumotingizni kiriting"
                      : "(Hozirda ro'yxatga turgan joyingizni kiriting)"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    {" "}
                    <TextField
                      id='outlined-size-small'
                      size='small'
                      fullWidth
                      type='text'
                      required
                      value={passportSeria}
                      onChange={(e) => setPassportSeria(e.target.value)}
                      error={ErrorFunction(passportSeria)}
                      helperText={
                        ErrorFunction(passportSeria) &&
                        "Iltimos, ma'lumotingizni kiriting"
                      }
                      label='Passport seriayasi'
                      inputProps={{ maxLength: 2 }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    {" "}
                    <TextField
                      id='outlined-size-small'
                      size='small'
                      fullWidth
                      type='number'
                      required
                      defaultValue={userInfo?.passportInfo?.docNumber}
                      label='Passport raqami'
                      value={passportNumber}
                      onChange={(e) => setPassportNumber(e.target.value)}
                      error={ErrorFunction(passportNumber)}
                      helperText={
                        ErrorFunction(passportNumber) &&
                        "Iltimos, ma'lumotingizni kiriting"
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id='outlined-size-small'
                  size='small'
                  fullWidth
                  type='text'
                  required
                  value={passportIsGiven}
                  onChange={(e) => setPassportIsGiven(e.target.value)}
                  label='Kim tomonidan berilgan'
                  error={ErrorFunction(passportIsGiven)}
                  helperText={
                    ErrorFunction(passportIsGiven) &&
                    "Iltimos, ma'lumotingizni kiriting"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {passportId ? (
                  <span>
                    Passport yoki guvohnomani PDF formatda (rangli tasvirda, 5
                    mb dan oshmagan holda) *
                  </span>
                ) : (
                  <span className='text-error'>
                    Passport yoki guvohnomani PDF formatda (rangli tasvirda, 5
                    mb dan oshmagan holda) *
                  </span>
                )}
                <MDBValidationItem feedback='Smth' invalid>
                  <input
                    type='file'
                    className={
                      passportId ? "form-control" : "form-control-error"
                    }
                    aria-label='file example'
                    required
                    accept='application/pdf'
                    onChange={passportPicture}
                  />
                  {userInfo?.passportInfo?.passportAttachment?.fullPath && (
                    <Button
                      href={
                        userInfo?.passportInfo?.passportAttachment?.fullPath
                      }
                      className={"text-subtitle"}
                      target='_blank'
                    >
                      File:{" "}
                      {userInfo?.passportInfo?.passportAttachment?.originalName}
                    </Button>
                  )}
                </MDBValidationItem>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: 3 }}>
            <Typography variant={"h5"}>
              Diplom ma'lumotlar tahrirlash
              <span style={{ fontSize: "18px", color: "#fdc632" }}>
                {userInfo?.fieldInfo?.studyType === "BAKALAVR"
                  ? " (Bakalaviriat)"
                  : " (Magistratura)"}
              </span>
            </Typography>

            <Grid container spacing={2} sx={{ py: 2 }}>
              <Grid item xs={12} md={6} sx={{ zIndex: "999" }}>
                {degree ? (
                  <span>Ma'lumotini tanlang *</span>
                ) : (
                  <span className='text-error'>Ma'lumotini tanlang *</span>
                )}
                <Select
                  styles={degree ? colorStyles : isError}
                  options={daraja}
                  placeholder={userInfo?.diplomaInfo?.malumoti}
                  onChange={handleDegree}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ zIndex: "998" }}>
                {educationType ? (
                  <span>Muassasa turi *</span>
                ) : (
                  <span className='text-error'>Muassasa turi *</span>
                )}
                <Select
                  styles={educationType ? colorStyles : isError}
                  placeholder={userInfo?.diplomaInfo?.muassasaTuri}
                  options={type}
                  onChange={handleEducationType}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id='outlined-size-small'
                  size='small'
                  fullWidth
                  type='text'
                  required
                  label='Muassasa nomi'
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  error={ErrorFunction(companyName)}
                  helperText={
                    ErrorFunction(companyName) &&
                    "Iltimos, ma'lumotingizni kiriting"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id='outlined-size-small'
                  size='small'
                  fullWidth
                  type='number'
                  required
                  label='Tugatgan yili'
                  value={graduatedYear}
                  onChange={(e) => setGraduatedYear(e.target.value)}
                  error={ErrorFunction(graduatedYear)}
                  helperText={
                    ErrorFunction(graduatedYear) &&
                    "Iltimos, ma'lumotingizni kiriting"
                  }
                />
              </Grid>

              <Grid item xs={12} md={6} sx={{ zIndex: "997" }}>
                {diplomLivePlace ? (
                  <span>Tugatgan muassasa joylashgan viloyat *</span>
                ) : (
                  <span className='text-error'>
                    Tugatgan muassasa joylashgan viloyat *
                  </span>
                )}
                <Select
                  styles={diplomLivePlace ? colorStyles : isError}
                  options={viloyat}
                  placeholder={userInfo?.diplomaInfo?.region}
                  onChange={handleDiplomLivePlace}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ zIndex: "996" }}>
                {diplomAddress ? (
                  <span>Tuman *</span>
                ) : (
                  <span className='text-error'>Tuman *</span>
                )}
                <Select
                  styles={diplomAddress ? colorStyles : isError}
                  options={diplomRegion}
                  placeholder={userInfo?.diplomaInfo?.district}
                  onChange={handleDiplomAddress}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {diplomId ? (
                  <span>
                    Diplom yoki attestatni yuklash ( 5 mb dan oshmagan holda) *
                  </span>
                ) : (
                  <span className='text-error'>
                    Diplom yoki attestatni yuklash ( 5 mb dan oshmagan holda) *
                  </span>
                )}
                <MDBValidationItem invalid>
                  <input
                    type='file'
                    className={diplomId ? "form-control" : "form-control-error"}
                    aria-label='file example'
                    required
                    accept='application/pdf'
                    onChange={diplomPicture}
                  />
                  {userInfo?.diplomaInfo?.diplomaAttachment?.fullPath && (
                    <Button
                      href={userInfo?.diplomaInfo?.diplomaAttachment?.fullPath}
                      className={"text-subtitle"}
                      target='_blank'
                    >
                      File:{" "}
                      {userInfo?.diplomaInfo?.diplomaAttachment?.originalName}
                    </Button>
                  )}
                </MDBValidationItem>
              </Grid>
              {submitHandler === "MAXSUS_SIRTQI" ? (
                <Grid item xs={12} md={6}>
                  {tavsiyanomaId ? (
                    <span>
                      Tavsiyanomani yuklash (5 mb dan oshmagan holda) *
                    </span>
                  ) : (
                    <span className='text-error'>
                      Tavsiyanomani yuklash (5 mb dan oshmagan holda) *
                    </span>
                  )}
                  <MDBValidationItem invalid>
                    <input
                      type='file'
                      className={
                        tavsiyanomaId ? "form-control" : "form-control-error"
                      }
                      aria-label='file example'
                      required
                      accept='application/pdf'
                      onChange={tavsiyanomaPicture}
                    />
                    {userInfo?.diplomaInfo?.tavsiyanomaAttachment?.fullPath && (
                      <Button
                        href={
                          userInfo?.diplomaInfo?.tavsiyanomaAttachment?.fullPath
                        }
                        target='_blank'
                        className={"text-subtitle"}
                      >
                        File:{" "}
                        {
                          userInfo?.diplomaInfo?.tavsiyanomaAttachment
                            ?.originalName
                        }
                      </Button>
                    )}
                  </MDBValidationItem>
                </Grid>
              ) : null}
              {submitHandler === "MAXSUS_SIRTQI" ? (
                <Grid item xs={12} md={6}>
                  {daftarId ? (
                    <span>
                      Mehnat daftarchasini nusxasini yuklash ( 5 mb dan oshmagan
                      holda) *
                    </span>
                  ) : (
                    <span className='text-error'>
                      Mehnat daftarchasini nusxasini yuklash ( 5 mb dan oshmagan
                      holda) *
                    </span>
                  )}

                  <MDBValidationItem invalid>
                    <input
                      type='file'
                      className={
                        daftarId ? "form-control" : "form-control-error"
                      }
                      aria-label='file example'
                      required
                      accept='application/pdf'
                      onChange={mehnatDaftar}
                    />
                    {userInfo?.diplomaInfo?.mehnatDaftarAttachment
                      ?.fullPath && (
                      <Button
                        href={
                          userInfo?.diplomaInfo?.mehnatDaftarAttachment
                            ?.fullPath
                        }
                        target='_blank'
                        className={"text-subtitle"}
                      >
                        File:{" "}
                        {
                          userInfo?.diplomaInfo?.mehnatDaftarAttachment
                            ?.originalName
                        }
                      </Button>
                    )}
                  </MDBValidationItem>
                </Grid>
              ) : null}

              <Grid item xs={12} md={6}>
                <span>
                  Xalqaro darajadagi sertifikatni yuklash (IELTS,TOEFL..., 5 mb
                  dan oshmagan holda)
                </span>

                <MDBValidationItem invalid>
                  <input
                    type='file'
                    className={"form-control"}
                    aria-label='file example'
                    accept='application/pdf'
                    onChange={IeltsCopy}
                  />
                  {userInfo?.diplomaInfo?.ieltsAttachment?.fullPath && (
                    <Button
                      href={userInfo?.diplomaInfo?.ieltsAttachment?.fullPath}
                      className={"text-subtitle"}
                      target='_blank'
                    >
                      File:{" "}
                      {userInfo?.diplomaInfo?.ieltsAttachment?.originalName}
                    </Button>
                  )}
                </MDBValidationItem>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    {" "}
                    <TextField
                      id='outlined-size-small'
                      size='small'
                      fullWidth
                      type='text'
                      label='Diplom yoki attestat seriasi'
                      inputProps={{ maxLength: 3 }}
                      value={diplomSeria}
                      onChange={(e) => setDiplomSeria(e.target.value)}
                      error={ErrorFunction(diplomSeria)}
                      helperText={
                        ErrorFunction(diplomSeria) &&
                        "Iltimos, ma'lumotingizni kiriting"
                      }
                    />
                  </Grid>
                  <Grid item xs={8}>
                    {" "}
                    <TextField
                      id='outlined-size-small'
                      size='small'
                      fullWidth
                      type='number'
                      label='Diplom yoki attestat raqami'
                      value={diplomNumber}
                      onChange={(e) => setDiplomNumber(e.target.value)}
                      error={ErrorFunction(diplomNumber)}
                      helperText={
                        ErrorFunction(diplomNumber) &&
                        "Iltimos, ma'lumotingizni kiriting"
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: 3 }}>
            <Typography variant={"h5"}>
              Ta'lim yo'nalishini tanlash
              <span style={{ fontSize: "18px", color: "#fdc632" }}>
                {userInfo?.fieldInfo?.studyType === "BAKALAVR"
                  ? " (Bakalaviriat)"
                  : " (Magistratura)"}
              </span>
            </Typography>

            <Grid container spacing={2} sx={{ py: 2 }}>
              <Grid item xs={12} md={6} sx={{ zIndex: "995" }}>
                <Select
                  options={categories}
                  placeholder={
                    userInfo?.fieldInfo?.fieldType === "KUNDUZGI" &&
                    userInfo?.fieldInfo?.studyType === "MAGISTR"
                      ? "Magistratura"
                      : userInfo?.fieldInfo?.fieldType === "SIRTQI" &&
                        userInfo?.fieldInfo?.studyType === "BAKALAVR"
                      ? "Bakalavr (sirtqi)"
                      : userInfo?.fieldInfo?.fieldType === "KUNDUZGI" &&
                        userInfo?.fieldInfo?.studyType === "BAKALAVR"
                      ? "Bakalavr (kunduzgi)"
                      : "Bakalavr (Maxsus Sirtqi)"
                  }
                  onChange={(option) => isFoundType(option.value)}
                  styles={
                    userInfo?.fieldInfo?.fieldType ? colorStyles : isError
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ zIndex: "994" }}>
                <Select
                  styles={fieldId ? colorStyles : isError}
                  placeholder={userInfo?.fieldInfo?.fieldName}
                  options={turi}
                  onChange={findFieldId}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: 3 }}>
            <Typography variant={"h5"}>
              Bog'lanish uchun ma'lumotlarni tahrirlash
              <span style={{ fontSize: "18px", color: "#fdc632" }}>
                {userInfo?.fieldInfo?.studyType === "BAKALAVR"
                  ? " (Bakalaviriat)"
                  : " (Magistratura)"}
              </span>
            </Typography>

            <Grid container spacing={2} sx={{ py: 2 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  id='outlined-size-small'
                  size='small'
                  fullWidth
                  type='number'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  label='Telefon raqam'
                  error={ErrorFunction(phone)}
                  helperText={
                    ErrorFunction(phone) &&
                    "Iltimos, telefon raqamingizni kiriting"
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id='outlined-size-small'
                  size='small'
                  fullWidth
                  type='email'
                  label='email'
                  defaultValue={userInfo?.userInfo?.email}
                  inputRef={email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MDBTextArea
                  label='Izoh qoldiring...'
                  id='textAreaExample'
                  rows={4}
                  inputRef={comment}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ pt: 0, pb: 5, display: "flex", justifyContent: "center" }}>
            <Button
              variant='outlined'
              sx={{ px: 4, py: 1 }}
              onClick={
                submitHandler === "MAXSUS_SIRTQI"
                  ? onSubmitHandler
                  : onSubmitHandlerMagistr
              }
            >
              Ma'lumotlarni yuborish
            </Button>
          </Box>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

/** @format */

import axios from "axios";

const interceptor = axios.create({
  // baseURL: "https://qabul.nordicmail.uz/",
  baseURL: "https://admission.nordicun.uz/",

  headers: {
    "Content-Type": "application/json",
  },
});

export default interceptor;
